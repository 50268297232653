<template>
    <v-container fluid>

        <v-data-table :headers="headers" :items="items" :items-per-page="10" item-key="id" sort-by="marca"
            :search="search" mobile-breakpoint="0" calculate-widths class="elevation-4" :loading="loading_status"
            loader-height="10" loading-text="Cargando ...">
            <template #top>
                <v-toolbar rounded flat>
                    <h3>Ingresar Compra</h3>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-form v-model="searchvalid" ref="form" @submit="get_report">
                    <v-row class="pa-2">
                        <v-col cols="12" md="3">
                            <v-text-field v-model="dateFrom" label="Desde" hide-details="auto" required outlined
                                type="date" :rules="f_required" />
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field v-model="dateTo" label="Hasta" hide-details="auto" required outlined
                                type="date" :rules="f_required" />
                        </v-col>
                        <!-- <v-col cols="12" md="3">
                            <v-select v-model="brand" :items="brands" item-value="codigo" item-text="valor"
                                label="Marca" placeholder="Marca" clearable hide-details="auto" outlined />
                        </v-col> -->
                        <v-col cols="12" md="2">
                            <v-btn x-large color="success" ref="submitbtn" type="submit">Buscar</v-btn>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field v-model="search" label="Buscar" class="mx-4"></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </template>

            <template v-slot:[`item.costo`]="{ item }">
                <span>{{ parseFloat(item.costo).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.tcosto`]="{ item }">
                <span>{{ "$" + (parseFloat(item.costo) * parseFloat(item.quantity)).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.price`]="{ item }">
                <span>{{ "$" + parseFloat(item.price).toLocaleString(2) }}</span>
            </template>
            <template v-slot:[`item.tprecio`]="{ item }">
                <span>{{ "$" + (parseFloat(item.price) * parseFloat(item.quantity)).toLocaleString(2) }}</span>
            </template>


            <template v-slot:[`item.actions`]="{ item }">
                <v-icon class="mr-2" @click="showDoc(item)">
                    mdi-magnify
                </v-icon>
            </template>


            <template slot="body.append">
                <tr>
                    <th>Total</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th class="text-right">{{ sumTable('items', 'quantity') }}</th>
                    <th></th>
                    <th class="text-right">{{ sumTable('items', 'tcosto') }}</th>
                    <th></th>
                    <th class="text-right">{{ "$ " + sumTable('items', 'tprecio') }}</th>
                </tr>
            </template>
        </v-data-table>




    </v-container>
</template>

<script>
import { webserver, getToday, getdays_ago } from "../services/webserver.js";

import createDoc from "../utils/create_doc.js";
export default {
    components: {},
    data() {
        return {
            search: '',
            loading_status: false,
            headers: [
                {
                    text: "Fecha",
                    align: "start",
                    sortable: true,
                    filterable: true,
                    value: "order_date",
                    dataType: "text",
                },
                {
                    text: "Documento",
                    align: "start",
                    sortable: true,
                    filterable: true,
                    value: "order_number",
                    dataType: "text",
                },
                {
                    text: "Categoría",
                    align: "start",
                    sortable: true,
                    filterable: true,
                    value: "categoria",
                    dataType: "text",
                },
                {
                    text: "Grupo",
                    align: "start",
                    sortable: true,
                    filterable: true,
                    value: "grupo",
                    dataType: "text",
                },
                {
                    text: "Marca",
                    align: "start",
                    sortable: true,
                    filterable: true,
                    value: "brand",
                    dataType: "text",
                },
                {
                    text: "Referencia",
                    align: "start",
                    sortable: true,
                    filterable: true,
                    value: "refe",
                    dataType: "text",
                },
                {
                    text: "Color",
                    align: "start",
                    sortable: true,
                    filterable: true,
                    value: "color_name",
                    dataType: "text",
                },
                {
                    text: "Cantidad",
                    align: "end",
                    sortable: true,
                    filterable: true,
                    value: "quantity",
                    dataType: "text",
                },
                {
                    text: "Costo Un",
                    align: "end",
                    sortable: true,
                    filterable: true,
                    value: "costo",
                    dataType: "number",
                },
                {
                    text: "Costo",
                    align: "end",
                    sortable: true,
                    filterable: true,
                    value: "tcosto",
                    dataType: "number",
                },
                {
                    text: "Precio Un",
                    align: "end",
                    sortable: true,
                    filterable: true,
                    value: "price",
                    dataType: "number",
                },
                {
                    text: "Precio",
                    align: "end",
                    sortable: true,
                    filterable: true,
                    value: "tprecio",
                    dataType: "number",
                },
            ],
            items: [],
            dialog: false,
            item: createDoc(),
            dateFrom: getdays_ago(-30),
            dateTo: getToday(),
            chartData: null,
            chartOptions: {
                curveType: "function",
                legend: { position: "right" },
                interpolateNulls: true,
                crosshair: { trigger: "both", opacity: 0.95, color: "#39FF14" },
                title: "",
                isStacked: false,
                hAxis: {
                    title: "",
                    slantedText: true,
                    slantedTextAngle: 15,
                },
                colors: ['#1b9e77', '#d95f02', '#7570b3'],
                height: 400,
            },
            brand: null,
            brands: [],
            f_required: [(v) => !!v || "Requerido"],
            searchvalid: false,
            docId: null,
            docDate: null,
            orderItems: [],
            order_dialog: false,
            selected: [],
            opin: [],
            typeIn: null,
            docRefIn: null,
            validIn: false,
            SelectedCount: 0,
            error_form: false,
            errorOrder: false,
            timeout: 5000,
        };
    },

    mounted() {
        this.brands = window.settings["MARCA"].sort(this.GetSortOrder("valor"));
    },
    methods: {
        showDoc(item) {
            this.selected = [];
            this.docId = item.order_id;
            this.docDate = item.order_date;
            this.$refs.submitbtn.$el.click();
            this.order_dialog = true;

        },
        sumTable(table, key) {
            return parseFloat(this[table].reduce((a, b) => a + (parseFloat(b[key]) || 0), 0)).toLocaleString(2)
        },
        get_report(e) {
            e.preventDefault();
            this.$refs.form.validate();
            if (this.searchvalid) {
                this.loading_status = true;
                var qry = {
                    store: window.store.store_id,
                    dateFrom: this.dateFrom,
                    dateTo: this.dateTo,
                    order_id: this.docId,
                    brand: this.brand,
                };
                this.loading_status = true;
                webserver("get_stock_in", qry, (data) => {
                    console.log(data)
                    this.items = data;
                    this.loading_status = false;
                });
            }
        },
        GetSortOrder(prop) {
            return function (a, b) {
                if (a[prop] > b[prop]) {
                    return 1;
                } else if (a[prop] < b[prop]) {
                    return -1;
                }
                return 0;
            };
        },
        StockIn(e) {
            e.preventDefault();
            this.$refs.formIn.validate();
            if (this.validIn) {

                var qry = {};
                qry.store = window.store.store_id,
                    qry.issueDate = getToday();
                qry.user_id = window.profile.user_email,
                    qry.orderRef = this.typeIn + '-' + this.docRefIn;
                qry.data = this.selected;
                console.log(qry);
                this.loading_status = true;
                webserver("order_in", qry, (data) => {
                    console.log(data);
                    this.typeIn = null;
                    this.docRefIn = null;
                    this.loading_status = false;
                    this.order_dialog = false;
                    this.$refs.submitbtn.$el.click();
                });
            }


        }
    },
    watch: {
        selected: function () {
            this.SelectedCount = 0;
            this.selected.forEach((itm) => {
                this.SelectedCount += parseFloat(itm.quantity);
            });
        },
    },
};
</script>

<style>
.v-dialog {
    overflow-y: hidden !important;
}
</style>
